
















import type {
  ScriptPropertyJson,
  ScriptPropertySrc,
  ScriptPropertySrcCallback,
  ScriptPropertyText,
} from 'vue-meta/types/vue-meta';

import { storeToRefs } from 'pinia';
import {
  computed,
  defineComponent,
  onMounted,
  useMeta,
  provide,
} from '@nuxtjs/composition-api';
import {
  ROUTES,
  useJsonLinkedData,
  useMonetate,
  useHistory,
} from '@vf/composables';
import { useCyberSourceStore } from '@vf/composables/src/store/cyberSourceStore';
import { useSimplifiedEnrollmentStore } from '@vf/composables/src/store/simplifiedEnrollment';
import { useFeatureFlagsStore } from '@vf/composables/src/store/featureFlags';

import ModalManager from '@/components/modals/ModalManager.vue';
import PageLoader from '@/components/PageLoader.vue';
import Spinner from '@/components/loaders/Spinner.vue';
import ProgressiveProfileManager from '@/components/static/simplifiedEnrollment/ProgressiveProfileManager.vue';

import { isClient } from '@vf/shared/src/utils/helpers';
import useUIContextProvider from '@/shared/useUIContextProvider';
import useGlobalState from '@/shared/useGlobalState';
import useRootInstance from '@/shared/useRootInstance';
import SkipToContent from '@/components/accessibility/SkipToContent.vue';

export default defineComponent({
  components: {
    SkipToContent,
    PageLoader,
    ModalManager,
    Spinner,
    ProgressiveProfileManager,
  },

  setup() {
    const { root } = useRootInstance();
    const { monetateScriptUrl } = useMonetate(root);

    const cyberSourceStore = useCyberSourceStore();
    const {
      getPageJsonLinkedData,
      getPageWebsiteJsonLinkedData,
      getPageBreadcrumbJsonLinkedData,
    } = useJsonLinkedData(root, 'page-content');
    const pageWebsiteJsonLinkedData = getPageWebsiteJsonLinkedData();
    const { updateHistoryWithAkamaiRedirection } = useHistory();

    const featureFlagsStore = useFeatureFlagsStore();
    const {
      isCheckoutRedesignEnabled,
      isCoreRedesignEnabled,
      isFiltersUiUpdateEnabled,
      isMobileAppMetatagEnabled,
      isQueueItWaitingRoomProxyEnabled,
      isQueueItEnabled,
      isSimplifiedEnrollmentEnabled,
      isVansPdpRedesignEnabled,
      isVansPlpRedesignEnabled,
    } = storeToRefs(featureFlagsStore);

    const { activeComponent } = storeToRefs(useSimplifiedEnrollmentStore(root));

    // TODO: GLOBAL15-56318 clean up
    provide('isCheckoutRedesignEnabled', isCheckoutRedesignEnabled);
    // TODO: GLOBAL15-61059 remove after core redesign
    provide('isCoreRedesignEnabled', isCoreRedesignEnabled);
    // TODO: GLOBAL15-62475 remove after filters redesign
    provide('isFiltersUiUpdateEnabled', isFiltersUiUpdateEnabled);
    // TODO: GLOBAL15-63801 remove after pdp redesign
    provide('isVansPdpRedesignEnabled', isVansPdpRedesignEnabled);
    // TODO: GLOBAL15-63799 remove after plp redesign
    provide('isVansPlpRedesignEnabled', isVansPlpRedesignEnabled);

    const isCheckoutBillingPage = computed(() =>
      root.$route.path.endsWith(ROUTES.CHECKOUT_PAYMENT())
    );

    const isCheckoutShippingPage = computed(() =>
      root.$route.path.endsWith(ROUTES.CHECKOUT_SHIPPING())
    );

    useUIContextProvider();

    useMeta(() => {
      const scripts: (
        | ScriptPropertyText
        | ScriptPropertySrc
        | ScriptPropertySrcCallback
        | ScriptPropertyJson
      )[] = [
        {
          type: 'text/javascript',
          src: monetateScriptUrl,
          defer: true,
        },
        pageWebsiteJsonLinkedData,
      ];

      const pageJsonLikedData = getPageJsonLinkedData();
      if (pageJsonLikedData?.value) {
        scripts.push(pageJsonLikedData.value);
      }

      const breadcrumbJsonLinkedData = getPageBreadcrumbJsonLinkedData(
        root.$route.path
      );

      if (breadcrumbJsonLinkedData) {
        scripts.push(breadcrumbJsonLinkedData);
      }

      if (isCheckoutShippingPage.value || isCheckoutBillingPage.value) {
        scripts.push({
          src: cyberSourceStore.deviceFingerPrintURL,
        });
      }

      if (isQueueItEnabled.value) {
        const queueItConfig = JSON.parse(root.$env.QUEUE_IT_CONFIG);
        const scriptsDomain = isQueueItWaitingRoomProxyEnabled.value
          ? queueItConfig.waiting_room_proxy
          : queueItConfig.queue_it_domain;

        scripts.push({
          type: 'text/javascript',
          src: `${scriptsDomain}${queueItConfig.paths.client_script}`,
          defer: true,
        });
        scripts.push({
          type: 'text/javascript',
          src: `${scriptsDomain}${queueItConfig.paths.config_script}`,
          'data-queueit-intercept-domain': `//${root.$getDomainName()}/`,
          'data-queueit-intercept': 'true',
          'data-queueit-spa': 'true',
          'data-queueit-c': root.$env.THEME,
          defer: true,
        });
      }
      return {
        script: scripts,
        meta: [
          {
            name: 'facebook-domain-verification',
            content: root.$env.FB_VERIFICATION_CODE,
          },
          {
            name: 'google-site-verification',
            content: root.$env.GOOGLE_SITE_VERIFICATION,
          },
          {
            ...((isMobileAppMetatagEnabled.value &&
              root.$getConfigValueByCurrentLocale<any>('MAPP_METATAG')) ||
              {}),
          },
        ].filter(({ content }) => Boolean(content)),
      };
    });

    const routerClassList = computed(() => {
      return root.$route ? root.$route?.path.replace(/\//gm, ' page-') : '';
    });

    // added to print current deployed version to console, it's evaluated build-time
    root.$log.info('UI Version', { commit: root.$config.GIT_COMMIT });

    onMounted(async () => {
      await cyberSourceStore.prepareDeviceFingerprinting();
      updateHistoryWithAkamaiRedirection();
    });

    return {
      routerClassList,
      loading: isClient ? useGlobalState().loading : false,
      isCoreRedesignEnabled,
      activeComponent,
      isSimplifiedEnrollmentEnabled,
    };
  },
  // TODO: GLOBAL15-61059 remove after redesign core
  head() {
    return {
      bodyAttrs: {
        class: this.isCoreRedesignEnabled ? 'redesign-core' : undefined,
      },
    };
  },
});
