

















































































































































































































































































































































































































































































































































































































































































































































































































































import { disableBodyScroll, clearAllBodyScrollLocks } from 'body-scroll-lock';
import ClickOutside from 'vue-click-outside';
import { validationMixin } from 'vuelidate';
import { required } from 'vuelidate/lib/validators';
import { AnalyticsDataDomLocation } from '@/types';
import { PromotionMessageContext } from '@vf/api-contract';
import { ROUTES } from '@vf/composables';

import { isClient } from '@vf/shared/src/utils/helpers';
import throttle from '@vf/shared/src/utils/helpers/throttle';
import { scrollTo as scrollToTop } from '@vf/shared/src/utils/helpers/scrollTo';
import { hexToRGBA } from '@/helpers/hexToRGBA';

import CartIcon from './CartIcon.vue';
import HeaderMegaMenuMobile from './HeaderMegaMenuMobile.vue';
import Column from '../grid/Column.vue';
import { useFeatureFlagsStore } from '@vf/composables/src/store/featureFlags';
import { storeToRefs } from 'pinia';
import { useUserStore } from '@vf/composables/src/store/user';
import useRootInstance from '@/shared/useRootInstance';
import { isCategorySegment } from '@vf/composables/src/useUrl/handlers/parseUrl';
import { defineComponent, watch, ref } from '@vue/composition-api';
import { focusTrap } from '@vf/shared/src/utils/directives';

export default defineComponent({
  name: 'HeaderMegaMenu',
  components: {
    HeaderMegaMenuMobile,
    Column,
    PromotionMessage: () => import('../promotion/PromotionMessage.vue'),
    MiniCartPopUp: () => import('./MiniCartPopUp.vue'),
    LoyaltyRewardsProgress: () =>
      import('../loyalty/LoyaltyRewardsProgress.vue'),
    CartIcon,
  },
  directives: {
    ClickOutside,
    focusTrap,
  },
  mixins: [validationMixin],
  props: {
    logo: {
      type: [Object],
      default: () => ({}),
    },
    title: {
      type: String,
      default: '',
    },
    navigation: {
      type: Array,
      default: () => [],
    },
    utilityNavigation: {
      type: Object,
      default: () => ({ desktop: [], mobile: [] }),
    },
    searchButtonText: {
      type: String,
      default: '',
    },
    steps: {
      type: Array,
      default: () => [],
    },
    sticky: Boolean,
    mobileNav: {
      type: Object,
      default: () => ({ items: [], id: '', translations: {} }),
    },
    promoBar: {
      type: Object,
      default: () => ({}),
    },
    searchBar: {
      type: Object,
      default: null,
    },
    miniCart: {
      type: Object,
      default: null,
    },
    isMinicartOpen: Boolean,
    favoritesTooltip: {
      type: Object,
      default: null,
    },
    isFavoritesTooltipOpen: {
      type: Boolean,
      default: false,
    },
    favoritesListUrl: {
      type: String,
      default: '',
    },
    createAccountUrl: {
      type: String,
      default: '',
    },
    isCustomerLoggedIn: {
      type: Boolean,
      default: false,
    },
    favoritesCount: {
      type: Number,
      default: 0,
    },
    recentlyAddedProductName: {
      type: String,
      default: '',
    },
    moreTitle: {
      type: String,
      default: '',
    },
    searchSuggestions: {
      type: Object,
      default: () => ({}),
    },
    getProductUrl: {
      type: Function,
      default: () => '',
    },
    getSearchLink: {
      type: Function,
      default: () => '',
    },
    maxItems: {
      type: Object,
      default: () => ({}),
    },
    menuTranslations: {
      type: Object,
      required: true,
    },
    headerConfig: {
      type: Object,
      required: true,
    },
    popupData: {
      type: Object,
      required: true,
    },
    mobileMenuSettings: {
      type: Object,
      default: () => ({}),
    },
    shopAll: {
      type: String,
      default: '',
    },
    loyaltyRewardsProgressTranslations: {
      type: Object,
      default: () => ({}),
    },
  },
  setup() {
    const { root } = useRootInstance();
    const {
      isCheckoutRedesignEnabled,
      isPointsToCurrencyEnabled,
      isFiltersUiUpdateEnabled,
      isCoreRedesignEnabled,
      isVansPlpRedesignEnabled,
      isMiniCartRedesignEnabled,
      enableMegaMenuSSR,
    } = useFeatureFlagsStore();
    const userStore = useUserStore(root);
    const { loyaltyEnrolled, loggedIn } = storeToRefs(userStore);
    const isListingPage = ref(false);
    const checkForListingPage = () =>
      isCategorySegment(root.$route.path) ||
      root.$route.path.includes(ROUTES.SEARCH())
        ? (isListingPage.value = true)
        : (isListingPage.value = false);
    const enableListingPageAnimation =
      isFiltersUiUpdateEnabled || isVansPlpRedesignEnabled;

    if (isClient) {
      watch(
        () => root.$route.path,
        () => checkForListingPage(),
        { immediate: true }
      );
    }

    const miniCartComponent = () =>
      import(
        `@/components/static/MiniCart${
          isMiniCartRedesignEnabled ? 'Redesign' : ''
        }.vue`
      );

    return {
      enableListingPageAnimation,
      enableMegaMenuSSR,
      isCheckoutRedesignEnabled,
      isCoreRedesignEnabled,
      isFiltersUiUpdateEnabled,
      isListingPage,
      isPointsToCurrencyEnabled,
      loggedIn,
      loyaltyEnrolled,
      miniCartComponent,
    };
  },
  data() {
    return {
      activeButton: null,
      height: 0,
      isMobileMenuVisible: false,
      isMobileSearchVisible: false,
      search: '',
      hidden: false,
      isPdpHeaderVisible: false,
      isFiltersSticky: false,
      collapsedHeader: false,
      headerSticky: false,
      slideUp: false,
      AnalyticsDataDomLocation,
      boxShadow: false,
      activeMegaMenuIndex: null,
      lastScrollPosition: 0,
      currentScrollPosition: 0,
      utilityNav: 0,
      closeIconName: this.$themeConfig.header?.closeIconName || 'cross',
      minicartIconName: this.$themeConfig.header.minicartIconName,
      enrollmentModalActive: false,
      delayTimer: null,
      isFocusTrapActive: false,
    };
  },
  computed: {
    getComponentVariant() {
      if (this.$themeConfig.header.enableVisualSearch) {
        return () => import('@vf/ui/components/Molecule.VisualSearchBar.vue');
      } else {
        return () => import('@vf/ui/components/Molecule.SearchBar.vue');
      }
    },
    isHeaderItemHovered() {
      return this.activeMegaMenuIndex !== null;
    },
    isMegaMenuActive() {
      return (
        this.activeMegaMenuIndex !== null &&
        this.checkColumnsContent(
          this.navigationToRender[this.activeMegaMenuIndex],
          this.activeMegaMenuIndex
        )
      );
    },
    headerHasFade() {
      if (this.currentScrollPosition > 0 || this.isHeaderItemHovered) {
        return false;
      }
      return this.headerConfig.isFadeEnabled;
    },
    backgroundColor() {
      if (this.currentScrollPosition > 0 || this.isHeaderItemHovered) {
        return '#fff';
      }
      return this.headerConfig.backgroundColor || '#fff';
    },
    fadeStyles() {
      return {
        '--header-background-fade-start': hexToRGBA(this.backgroundColor, 0.8),
        '--header-background-fade-end': hexToRGBA(this.backgroundColor, 0),
      };
    },
    promoBarMessage() {
      return this.promoBar.message?.filter((item) => {
        return (
          this.$root.$stateManagementChecker(item.states) &&
          this.$root.$segmentsChecker(item.segments)
        );
      });
    },
    showPromoBar() {
      return (
        (this.alwaysShowPromoBarMobile || !this.isMobileMenuVisible) &&
        this.promoBarMessage?.length
      );
    },
    promotionTranslations() {
      const translations = [];
      if (this.miniCart?.translations?.promotions) {
        translations.push({
          name: 'bounceBackVouchers',
          translations: this.miniCart.translations.promotions,
        });
      }
      return translations;
    },
    promotionContext() {
      return PromotionMessageContext.MiniCart;
    },
    mobileUtilityNavItems() {
      const result = this.mobileNav.items.filter(
        (item) =>
          item.visible &&
          this.$root.$stateManagementChecker(item.states) &&
          this.$root.$segmentsChecker(item.segments)
      );
      if (this.isCoreRedesignEnabled) {
        return ['search', 'logo', 'cart', 'menu'].reduce((acc, role) => {
          const item = result.find((item) => item.role === role);
          return [...acc, item];
        }, []);
      }
      return result;
    },
    clearSession() {
      return this.mobileUtilityNavItems.find(
        (item) =>
          item.role === 'clearSession' &&
          this.$root.$stateManagementChecker(item.states) &&
          this.$root.$segmentsChecker(item.segments)
      ); /** as ClearSession (@vf/api-contract) */
    },
    stickyMobile() {
      return this.sticky && this.$viewport && this.$viewport.isSmall;
    },
    stickyHeight() {
      return {
        '--_header-height': `${this.height}px`,
      };
    },
    isSearchSuggestionOpen() {
      return !!(this.search.length && this.hasSearchSuggestions);
    },
    isOverlayOpened() {
      return (
        (this.searchBar && this.isSearchSuggestionOpen) ||
        (this.isMegaMenuActive &&
          this.$themeConfig.header.showOverlayBehindMegaMenu)
      );
    },
    hasSearchSuggestions() {
      return !!(
        this.searchSuggestions.products?.length ||
        this.searchSuggestions.terms?.length
      );
    },
    checkoutTooltipText() {
      return (this.mobileNav.translations.checkoutTooltipText || '').replace(
        '{{productName}}',
        this.recentlyAddedProductName
      );
    },
    checkoutTooltipModifier() {
      return this.$themeConfig?.header?.checkoutTooltipModifier || 'secondary';
    },
    checkoutTooltipIcon() {
      return this.$themeConfig?.header?.checkoutTooltipIcon;
    },
    checkoutTooltipIconColor() {
      return this.$themeConfig?.header?.checkoutTooltipIconColor;
    },
    checkoutTooltipButtonClass() {
      return this.$themeConfig?.header?.checkoutTooltipButtonClass;
    },
    isSticky() {
      return this.headerConfig.isSticky;
    },
    transitionName() {
      return this.isSticky ? 'slide' : '';
    },
    searchBarHasClickOutside() {
      return this.$themeConfig?.header?.searchBarHasClickOutside;
    },
    disableBodyScrollInMenu() {
      return this.$themeConfig?.header?.disableBodyScrollInMenu;
    },
    showClearButtonInMobileSearch() {
      return this.$themeConfig?.header?.showClearButtonInMobileSearch;
    },
    showClearButtonInDesktopSearch() {
      return this.$themeConfig?.header?.showClearButtonInDesktopSearch;
    },
    searchSuggestionsThemeConfig() {
      return this.$root.$themeConfig.searchSuggestions || {};
    },
    showEmptySearchError() {
      return this.$themeConfig?.header?.showEmptySearchError;
    },
    desktopUtilityNavigationItems() {
      return this.utilityNavigation?.desktop?.filter((item) =>
        this.shouldLoginItemBeDisplayed(item)
      );
    },
    isMobileSearchVisibleIcon() {
      return this.$themeConfig?.header?.isMobileSearchVisibleIcon;
    },
    maxIndex() {
      return this.$viewport.size === 'large'
        ? this.maxItems.largeMaxItems
        : this.maxItems.mediumMaxItems;
    },
    navigationToRender() {
      return this.navigation.filter((i) => i.stateFulfilled);
    },
    throttledHandleScroll() {
      return throttle(this.handleScroll.bind(this), 400);
    },
    mobileIconSize() {
      return this.$themeConfig?.header?.mobileIconSize || '';
    },
    isMobileTopBarSticky() {
      return this.$themeConfig.header.isMobileTopBarSticky;
    },
    alwaysShowPromoBarMobile() {
      return this.$themeConfig.header.alwaysShowPromoBarMobile;
    },
    renderMinicartInstance() {
      // Minicart should be hidden only on the cart page
      return this.$route.path !== this.localePath(ROUTES.CART());
    },
  },
  watch: {
    '$route.path': {
      handler() {
        if (this.$route.query?.keepPosition === 'true') {
          return;
        }
        this.resetContentPositioning();
        this.isPdpHeaderVisible = false;
        this.resetMenuVisibility();
        this.currentScrollPosition = 0;
        this.lastScrollPosition = 0;
        this.$emit('close-favorites-tooltip');
      },
    },
    '$viewport.isSmall': {
      handler() {
        if (!isClient) return;
        this.mobileMenuFocusTrapDecision();
        this.resetContentPositioning();
      },
      immediate: true,
    },
    'promoBar.message': {
      handler() {
        this.resetContentPositioning();
      },
    },
    showPromoBar: {
      handler(isShowing) {
        // adding a conditional class to the bound class object removes all
        // other programatically added/removed classes not placed during render
        // adding the class here prevents other classes from being removed when
        // watcher changes the value that calculates classes
        const container = this.$refs.headerTopContainer;
        if (!isShowing && isClient) {
          this.closePromoBar();
          if (container)
            container.classList.add('vf-header__container--promo-bar-hidden');
        } else {
          if (container)
            container.classList.remove(
              'vf-header__container--promo-bar-hidden'
            );
        }
      },
      immediate: true,
    },
    headerConfig: {
      handler() {
        this.resetContentPositioning();
      },
      deep: true,
    },
    isMobileMenuVisible: {
      handler(value) {
        if (!isClient) return;
        if (value && this.disableBodyScrollInMenu) {
          this.$nextTick(() => {
            // Specifically, the target element is the one we would like to allow scroll on (NOT a parent of that element).
            disableBodyScroll(this.$refs['header']);
            // Fix body scroll for iOS for 12 version and older
            if (/iP(ad|hone)/.test(navigator.userAgent)) {
              document.body.classList.add('block-scroll');
            }
          });
        } else {
          this.$nextTick(() => {
            clearAllBodyScrollLocks();
          });
          // Fix body scroll for iOS for 12 version and older
          if (/iP(ad|hone)/.test(navigator.userAgent)) {
            document.body.classList.remove('block-scroll');
          }
        }
      },
      immediate: true,
    },
    isSticky: {
      handler(newValue, oldValue) {
        if (!isClient) return;
        if (!oldValue && newValue) {
          this.initAnimatedHeader();
        }
      },
    },
  },
  created() {
    this.utilityNav = this.$root.$themeConfig.header.minUtilityNavHeight;
  },
  mounted() {
    let topLevelLinks = document.querySelectorAll(
      '.vf-header-navigation-item--link'
    );
    topLevelLinks.forEach((topLevelLink) => {
      topLevelLink.addEventListener('keyup', (event: KeyboardEvent) => {
        if (event.keyCode === 37) {
          this.changeFocus(event, false);
        } else if (event.keyCode === 39) {
          this.changeFocus(event, true);
        }
      });
    });

    this.isSticky && this.initAnimatedHeader();
    // TODO: rethink this design to not use event bus
    // Set a listener for pdp pages that have their own sticky header
    this.$root.$eventBus.$on(
      'headerControlVisibility',
      (isPdpHeaderVisible) =>
        this.isSticky && this.handleHeaderVisibility(isPdpHeaderVisible)
    );
    this.$root.$eventBus.$on('filter-panel-sticky', this.onFilterPanelSticky);
    this.$root.$eventBus.$on('hide-header', this.onHideHeader);

    this.boxShadow = this.$root.$themeConfig?.header?.stickyHeader;

    this.$root.$eventBus.$on('close-mobile-menu', () => {
      this.isMobileMenuVisible = false;
      this.resetContentPositioning();
    });
  },
  beforeDestroy() {
    this.$emit('close-suggestions');
    this.removeScrollHandler();
    this.$root.$eventBus.$off('headerControlVisibility');
    this.$root.$eventBus.$off('filter-panel-sticky');
    this.$root.$eventBus.$off('close-mobile-menu');
    this.$root.$eventBus.$off('hide-header');
    this.onHideHeader(false);
    this.onFilterPanelSticky(false);
  },
  methods: {
    onFilterPanelSticky(isFiltersSticky) {
      if (this.isSticky) {
        this.isFiltersSticky = isFiltersSticky;
        document.documentElement.classList.toggle(
          'vf-header-filter-connected',
          isFiltersSticky
        );
      }
    },
    onHideHeader(hideHeader) {
      if (this.isSticky) {
        this.slideUp = hideHeader;
        document.documentElement.classList.toggle('header--hidden', hideHeader);
      }
    },
    setActiveButton(key) {
      this.activeButton = key;
    },
    showShopAll(item, value) {
      return item.navItemSettings[value];
    },
    displayLabel(item, value) {
      return item.navItemSettings[value].replace('{0}', item.title);
    },
    openMegaMenu(index) {
      this.activeMegaMenuIndex = index;
    },
    isMobileNavigationItemHidden(index) {
      return index >= this.maxItems.smallMaxItems;
    },
    toggleMobileHeaderStyle(isMenuOpen) {
      const hd = document.querySelector<HTMLElement>('.vf-header__animated');
      const hdContainer = document.querySelector<HTMLElement>(
        '.vf-header__container'
      );
      const themeVariantPrimary = this.$themeConfig?.header?.headerBackground
        ?.variantPrimary;
      const themeVariantSecondary = this.$themeConfig?.header?.headerBackground
        ?.variantSecondary;
      if (isMenuOpen) {
        hd.style.cssText = `
          --header-background-fade-start: ${themeVariantPrimary.start};
          --header-background-fade-end: ${themeVariantPrimary.end}`;
        hdContainer.style.cssText = `--header-background: ${themeVariantPrimary.backgroundColor}`;
        hd.classList.remove('vf-header__animated--fade');
      } else {
        hd.style.cssText = `
          --header-background-fade-start: ${themeVariantSecondary.start};
          --header-background-fade-end: ${themeVariantSecondary.end}`;
        hdContainer.style.cssText = `--header-background: ${themeVariantSecondary.backgroundColor}`;
        hd.classList.add('vf-header__animated--fade');
      }
      this.resetContentPositioning();
    },
    toggleMobileSearch() {
      this.isMobileMenuVisible = false;
      this.isMobileSearchVisible = !this.isMobileSearchVisible;
      if (!this.isMobileSearchVisible) {
        this.closeSuggestions(false, true);
      }
    },
    toggleMobileMenu() {
      this.isMobileSearchVisible = false;
      this.isMobileMenuVisible = !this.isMobileMenuVisible;
      if (
        this.headerHasFade &&
        this.$themeConfig?.header?.hasHeaderStyleVariants
      ) {
        this.toggleMobileHeaderStyle(this.isMobileMenuVisible);
      } else {
        this.resetContentPositioning();
      }

      this.mobileMenuFocusTrapDecision();
    },
    mobileMenuFocusTrapDecision() {
      this.$viewport.size === 'small' && this.isMobileMenuVisible
        ? this.startMobileMenuFocusTrap()
        : this.stopMobileMenuFocusTrap();
    },
    startMobileMenuFocusTrap() {
      if (!this.isFocusTrapActive) {
        focusTrap.bind(this.$el);
        this.isFocusTrapActive = true;
      }
    },
    stopMobileMenuFocusTrap() {
      if (this.isFocusTrapActive) {
        focusTrap.unbind(this.$el);
        this.isFocusTrapActive = false;
      }
    },
    closeSuggestions(keepFocus = true, isMobile = false) {
      this.$v.$reset();
      this.$emit('close-suggestions');
      this.isMobileSearchVisible = false;
      this.search = '';
      if (keepFocus) {
        if (!isMobile) {
          document
            .querySelector<HTMLInputElement>(
              '.vf-search-bar__desktop .vf-search-bar__input'
            )
            .focus();
        } else {
          document
            .querySelector<HTMLInputElement>(
              '.vf-search-bar__mobile .vf-search-bar__input'
            )
            .focus();
        }
      }
    },
    emitSetPdoSearch(value) {
      this.$emit('set-pdo-search', value);
    },
    closeSuggestionsByClickOutside() {
      if (!this.$viewport.isSmall) {
        this.closeSuggestions(false);
      }
    },
    closeMobileSuggestionsByClickOutside() {
      if (this.$viewport.isSmall) {
        this.closeSuggestions(false, true);
      }
    },
    closeMobileSearchByClickOutside(e) {
      let isSearchIconPressed = document
        .querySelector('.vf-header-mobile-nav-item-search')
        .contains(e.target);
      if (this.isMobileSearchVisible && !isSearchIconPressed) {
        this.toggleMobileSearch();
      }
    },
    changeSearch() {
      if (this.search.length < 3) {
        this.$emit('close-suggestions');
      } else {
        this.$emit('change-search', this.search);
      }
      this.emitSetPdoSearch({
        searchTerm: this.search,
        searchType: 'Standard',
      });
    },
    callSearch(payload) {
      this.search = payload;
      this.validateAndPropagateSearch();
    },
    validateAndPropagateSearch() {
      this.$v.$touch();
      if (!this.$v.$error) {
        this.$emit('close-suggestions');
        this.$emit('enter-search', this.search);
        this.emitSetPdoSearch({
          searchTerm: this.search,
          searchType: 'Standard',
        });
        this.closeSuggestions(false);
        this.isMobileSearchVisible = false;

        if (window.scrollY > window.innerHeight) {
          scrollToTop();
        }
      }
    },
    changeFocus(event, toRight) {
      const parent = event.currentTarget.parentNode;
      if (toRight) {
        parent.nextSibling.firstElementChild.focus();
      } else {
        parent.previousSibling.firstElementChild.focus();
      }
    },
    notifyAboutCollapse(transitioning) {
      this.$root.$eventBus.$emit('header-collapse', transitioning);
    },
    resetMenuVisibility() {
      this.openMegaMenu(null);
      this.isMobileMenuVisible = false;
    },
    handleNavLinkClick(data, category) {
      this.$emit('nav-menu-link-clicked', {
        link: data.link,
        l1: category,
        l2: data.l2,
        l3: data.l3,
        isModal: data.isModal,
      });
      this.resetMenuVisibility();
    },
    handleTopLevelNavLinkClick(e, data) {
      if (data.isModal) {
        e.preventDefault();
      }
      this.$emit('nav-menu-link-clicked', {
        link: data.link,
        l1: data.l1,
        l2: '',
        l3: '',
        isModal: data.isModal,
      });
      this.resetMenuVisibility();
    },
    onClearSession(link) {
      this.$emit('clear-session', link);
    },
    handleUtilityNavLinkClick(data) {
      this.$emit('utility-nav-menu-link-clicked', data);
    },
    checkoutTooltipButtonClick(link) {
      this.$emit('close-minicart');
      this.$router.push(link);
    },
    initAnimatedHeader() {
      /** Wait for full paint in order to read proper header height value */
      window.requestAnimationFrame(() => {
        let navHeight = this.$root.$viewport.isMobileDevice
          ? 0
          : this.$root.$themeConfig?.header?.minUtilityNavHeight;
        this.utilityNav =
          this.$refs['headerUtilityNav']?.$el?.offsetHeight || navHeight;
        this.changeContentPositioning();
      });
      window.addEventListener('scroll', this.throttledHandleScroll);
    },
    removeScrollHandler() {
      window.removeEventListener('scroll', this.throttledHandleScroll);
    },
    handleScroll() {
      if (!this.$viewport.isSmall) {
        this.openMegaMenu(null);
      }
      // Get the current scroll position
      this.currentScrollPosition =
        window.pageYOffset || document.documentElement.scrollTop;

      if (Math.abs(this.lastScrollPosition - this.currentScrollPosition) < 10) {
        return;
      }
      // handle the change no matter the direction
      if (this.currentScrollPosition - this.lastScrollPosition > 10) {
        this.openMegaMenu(null);
      }
      // Determine the sticky, collapsed, animated states
      if (this.currentScrollPosition > this.lastScrollPosition) {
        if (!this.$viewport.isSmall) this.collapsedHeader = true;
        this.headerSticky = true;
      } else {
        this.collapsedHeader = !!this.$root.$themeConfig?.header?.stickyHeader;
      }
      // Handle the top position
      if (this.currentScrollPosition <= 0) {
        this.headerSticky = false;
        this.collapsedHeader = false;
      }
      if (
        this.$refs['headerTopContainer']?.getAttribute(
          'data-vf-enable-sub-sticky'
        ) === 'true'
      ) {
        // when we have sub header sticky Utils navigation sholud be always visible
        this.collapsedHeader = false;
      }
      if (
        !this.$viewport.isSmall &&
        this.enableListingPageAnimation &&
        this.isListingPage
      )
        this.collapsedHeader = false;

      // Set the current scroll position as the last scroll position
      this.lastScrollPosition = this.currentScrollPosition;
    },
    closePromoBar() {
      document.documentElement.style.setProperty('--promo-bar-height', `0px`);
      this.$root.$eventBus.$emit('header-collapse', false);
      this.$nextTick(() => this.changeContentPositioning());
    },
    getPromobarHeight() {
      const promobar = this.$refs['headerPromo']?.$el;
      const promoBarHeight = promobar?.offsetHeight;

      if (!promobar || !promoBarHeight) {
        return 0;
      }

      document.documentElement.style.setProperty(
        '--promo-bar-height',
        `${promoBarHeight}px`
      );
      return promoBarHeight;
    },
    changeContentPositioning() {
      let headerContainerHeight =
        this.$refs['headerContainer']?.offsetHeight ?? 0;

      let headerHeight = 0;
      let utilityNavHeight = this.utilityNav;
      if (this.$root.$viewport.isSmall) {
        utilityNavHeight = 0;
        headerContainerHeight =
          this.$refs['headerContainerActions']?.offsetHeight ?? 0;
      }
      if (!this.headerHasFade) {
        headerHeight = headerContainerHeight + utilityNavHeight;
      }

      document.documentElement.style.setProperty(
        '--header-height',
        `${headerHeight}px`
      );
    },
    resetContentPositioning() {
      this.$nextTick(() => {
        this.height = this.$refs['headerContent']?.offsetHeight;
        if (this.isSticky || this.headerHasFade) {
          this.changeContentPositioning();
        }
      });
    },
    handleHeaderVisibility(isPdpHeaderVisible) {
      this.isPdpHeaderVisible = isPdpHeaderVisible;
    },
    checkColumnsContent(item, key) {
      if (item.columns?.length && this.activeMegaMenuIndex === key) {
        const isContent = item.columns.filter(
          (column) => column.level2MenuItems.length > 0
        );
        return isContent.length > 0;
      }
      return false;
    },
    getMenuItemAriaLabel(item, key) {
      return (this.checkColumnsContent(item, key)
        ? this.menuTranslations.closeCategorySubmenu
        : this.menuTranslations.openCategorySubmenu
      )?.replace('${item.title}', item.title);
    },
    shouldLoginItemBeDisplayed(item) {
      /* supports and edge case with temporary solution when we have
       * login elements in different orders on different viewports.
       * This function shouldn't be needed after:
       * https://digital.vfc.com/jira/browse/GLOBAL15-51742 is finished.
       *  */
      const isRoleLogin = item.role === 'login';
      if (!isRoleLogin) {
        return true;
      }

      const itemClasses = item.cssClass?.split(' ');
      if (!itemClasses) return true;

      const hasHiddenOnLargeClass = itemClasses.includes('hide-on-large');
      const hasHiddenOnMediumClass = itemClasses.includes('hide-on-medium');
      const isViewportMediumOrLarge = !this.$root.$viewport.isSmall;
      return !(
        isViewportMediumOrLarge &&
        hasHiddenOnLargeClass &&
        hasHiddenOnMediumClass
      );
    },
  },
  validations: {
    search: {
      required,
    },
  },
});
