












































import ModalManager from '@/components/modals/ModalManager.vue';
import Notifications from '@/components/smart/layout/Notifications.vue';
import HeaderMegaMenu from '@/components/smart/layout/HeaderMegaMenu.vue';
import Footer from '@/components/smart/layout/Footer.vue';
import Spinner from '@/components/loaders/Spinner.vue';
import ProgressiveProfileManager from '@/components/static/simplifiedEnrollment/ProgressiveProfileManager.vue';
import {
  useAuthentication,
  useJsonLinkedData,
  useInitSetup,
  useFavorites,
  useHeaderAndFooter,
  useHeaderProps,
  useSignInToStore,
  useMonetate,
  useHistory,
  useCart,
  useSearch,
  ROUTES,
  useI18n,
} from '@vf/composables';
import {
  computed,
  ref,
  ComputedRef,
  defineComponent,
  useAsync,
  useMeta,
  provide,
  useContext,
  onMounted,
  watch,
} from '@nuxtjs/composition-api';
import useRootInstance from '@/shared/useRootInstance';
import useModal from '@/shared/useModal';
import useLoader from '@/shared/useLoader';
import {
  ScriptPropertyJson,
  ScriptPropertySrc,
  ScriptPropertySrcCallback,
  ScriptPropertyText,
} from 'vue-meta/types/vue-meta';
import useUIContextProvider from '@/shared/useUIContextProvider';
import useGlobalState from '@/shared/useGlobalState';
import { isClient } from '@vf/shared/src/utils/helpers';
import { Context } from '@vf/api-contract';
import {
  PRESERVE_PREVIEW,
  addPreservePreviewParam,
} from '@/components/cms/cmsUtils';
import { useCyberSourceStore } from '@vf/composables/src/store/cyberSourceStore';
import { useUserStore } from '@vf/composables/src/store/user';
import { useFeatureFlagsStore } from '@vf/composables/src/store/featureFlags';
import { useSimplifiedEnrollmentStore } from '@vf/composables/src/store/simplifiedEnrollment';
import { storeToRefs } from 'pinia';
import SkipToContent from '@/components/accessibility/SkipToContent.vue';

export default defineComponent({
  components: {
    SkipToContent,
    HeaderMegaMenu,
    ModalManager,
    Spinner,
    Notifications,
    Footer,
    ProgressiveProfileManager,
  },

  setup() {
    const { root } = useRootInstance();
    const { monetateScriptUrl } = useMonetate(root);
    const { app } = useContext();
    const { prepareProps, headerEventHandlers } = useHeaderProps(root, {
      useLoader,
      useModal,
    });
    const { initSetup, verifySetup, apiClientRef } = useInitSetup(root);
    const { updateHistoryWithAkamaiRedirection } = useHistory();
    const { localePath } = useI18n(root);
    const { isFavoritesTooltipOpen } = useFavorites(root);
    const {
      prepareDeviceFingerprinting,
      deviceFingerPrintURL,
    } = useCyberSourceStore();

    /**
     * Non-Composable vars
     */
    const megaMenuProps = ref(null);
    const footerProps = ref({
      socialMedia: {
        items: [],
      },
    });
    const loading = isClient ? useGlobalState().loading : false;

    let updateHeaderAndFooterFunc;
    provide(
      'cmsApiClient',
      computed(() => apiClientRef)
    );
    provide('initSetup', initSetup);

    useUIContextProvider();

    async function init() {
      try {
        await initSetup({ forcePreview: app.$isPreview });
      } catch (error) {
        root.$log.error(
          `@theme/layouts/defaultStatic.vue::init Error: ${error.message}`
        );
        app.$nuxt.error({ statusCode: 404, message: 'Not found' });
      }
    }

    useAsync(async () => {
      await init();
      const {
        fetchHeaderAndFooter,
        updateHeaderAndFooterData,
        header,
        footer,
        errors,
        headerConfig,
      } = useHeaderAndFooter(
        root,
        verifySetup,
        apiClientRef.value,
        Context.PageContent
      );
      const { isMinicartOpen } = useCart(root);
      const { searchSuggestions } = useSearch(root);

      await fetchHeaderAndFooter();
      updateHeaderAndFooterFunc = updateHeaderAndFooterData;
      if (header.value?.children?.length) {
        megaMenuProps.value = prepareProps({
          ...header.value.children[0].props,
          headerConfig: headerConfig.value,
        });
        // Todo: this will be refactored within https://digital.vfc.com/jira/browse/GLOBAL15-59561
        megaMenuProps.value.isMinicartOpen = isMinicartOpen; // fix losing reactivity
        megaMenuProps.value.searchSuggestions = searchSuggestions; // fix losing reactivity
        megaMenuProps.value.isFavoritesTooltipOpen = isFavoritesTooltipOpen; // fix losing reactivity
      }
      if (footer.value?.children?.length) {
        footerProps.value = footer.value.children[0].props;
      }
      if (errors.value?.length) {
        root.$log.error(
          `@theme/layouts/defaultStatic.vue::useAsync Errors: ${errors.value.join(
            ' - '
          )}`
        );
      }
    });

    onMounted(() => {
      prepareDeviceFingerprinting();
      updateHistoryWithAkamaiRedirection();
      if (root.$isPreview) {
        const monetateParam = root.$route.query?.p13n_testcontext;
        window.sessionStorage.setItem(PRESERVE_PREVIEW, 'true');
        addPreservePreviewParam(monetateParam);
      }
    });

    const { getConsumerId } = useAuthentication(root);
    const userStore = useUserStore(root);
    const { loggedIn, loyaltyEnrolled } = storeToRefs(userStore);
    const { employeeConnected, hasStoreCookie } = useSignInToStore(root);
    const {
      getPageJsonLinkedData,
      getPageWebsiteJsonLinkedData,
      getPageBreadcrumbJsonLinkedData,
    } = useJsonLinkedData(root, Context.PageContent);

    // TODO: remove in GLOBAL15-56318
    // TODO: GLOBAL15-61059 remove after redesign core
    const {
      isCheckoutRedesignEnabled,
      isCheckoutSuccessRedesignEnabled,
      isCoreRedesignEnabled,
      isFiltersUiUpdateEnabled,
      isMobileAppMetatagEnabled,
      isVansPdpRedesignEnabled,
      isVansPlpRedesignEnabled,
      isSimplifiedEnrollmentEnabled,
    } = storeToRefs(useFeatureFlagsStore());

    const { activeComponent } = storeToRefs(useSimplifiedEnrollmentStore(root));

    // TODO: GLOBAL15-61059 remove after core redesign
    provide('isCoreRedesignEnabled', isCoreRedesignEnabled);
    provide(
      'isCheckoutSuccessRedesignEnabled',
      isCheckoutSuccessRedesignEnabled
    );
    // TODO: GLOBAL15-62475 remove after filters redesign
    provide('isFiltersUiUpdateEnabled', isFiltersUiUpdateEnabled);
    // TODO: GLOBAL15-63801 remove after pdp redesign
    provide('isVansPdpRedesignEnabled', isVansPdpRedesignEnabled);
    // TODO: GLOBAL15-63799 remove after plp redesign
    provide('isVansPlpRedesignEnabled', isVansPlpRedesignEnabled);

    const pageWebsiteJsonLinkedData = getPageWebsiteJsonLinkedData();

    useMeta(() => {
      const scripts: (
        | ScriptPropertyText
        | ScriptPropertySrc
        | ScriptPropertySrcCallback
        | ScriptPropertyJson
      )[] = [
        {
          type: 'text/javascript',
          src: monetateScriptUrl,
          defer: true,
        },
        pageWebsiteJsonLinkedData,
      ];

      const pageJsonLikedData: ComputedRef = getPageJsonLinkedData();

      if (pageJsonLikedData?.value) {
        scripts.push(pageJsonLikedData.value);
      }

      const breadcrumbJsonLinkedData = getPageBreadcrumbJsonLinkedData(
        root.$route.path
      );

      if (breadcrumbJsonLinkedData) {
        scripts.push(breadcrumbJsonLinkedData);
      }

      scripts.push({
        src: deviceFingerPrintURL,
      });

      return {
        script: scripts,
        meta: [
          {
            name: 'facebook-domain-verification',
            content: root.$env.FB_VERIFICATION_CODE,
          },
          {
            name: 'google-site-verification',
            content: root.$env.GOOGLE_SITE_VERIFICATION,
          },
          {
            ...((isMobileAppMetatagEnabled.value &&
              root.$getConfigValueByCurrentLocale<any>('MAPP_METATAG')) ||
              {}),
          },
        ].filter(({ content }) => Boolean(content)),
      };
    });

    watch(
      [loggedIn, loyaltyEnrolled, employeeConnected, hasStoreCookie],
      () => {
        updateHeaderAndFooterFunc();
        megaMenuProps.value.isCustomerLoggedIn = loggedIn.value;
      }
    );

    if (root.$config.areIpaWranxEnabled) {
      watch(
        getConsumerId,
        (currentValue, prevValue) => {
          if (loggedIn.value && currentValue && currentValue !== prevValue) {
            const { getSegments } = useAuthentication(root);
            getSegments();
          }
        },
        { immediate: true }
      );
    }

    const isCartPage = computed(
      () => root.$route.path === localePath(ROUTES.CART())
    );

    return {
      footerProps,
      headerEventHandlers,
      megaMenuProps,
      loading,
      isCartPage,
      isCheckoutRedesignEnabled,
      isCoreRedesignEnabled,
      activeComponent,
      isSimplifiedEnrollmentEnabled,
    };
  },
  // TODO: GLOBAL15-61059 remove after redesign core
  head() {
    return {
      bodyAttrs: {
        class: this.isCoreRedesignEnabled ? 'redesign-core' : undefined,
      },
    };
  },
});
